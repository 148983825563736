<template>
  <div>
    <el-button style="margin-bottom: 20px" type="success" @click="addActivity"
      >创建活动类型</el-button
    >
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #backgroundUrl>
        <el-table-column label="活动类型封面图" width="600" align="center">
          <template #default="scoped">
            <img
              v-if="scoped.row.backgroundUrl != ''"
              :src="scoped.row.backgroundUrl"
              style="width: 100px"
              @click="openImagePreview(scoped.row)"
            />
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="240" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleClickEdit(scoped.row)"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickDelete(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="activityTypeVisible"
      :title="activityTypeTitle"
      width="40%"
      :before-close="activityTypeClose"
    >
      <el-form
        ref="activityTypeFormRef"
        :model="activityTypeForm"
        :rules="activityTypeFormRules"
        label-width="140px"
        class="activityTypeForm"
      >
        <el-form-item label="活动类型：" prop="type">
          <el-input
            v-model="activityTypeForm.type"
            placeholder="请输入活动类型"
            :maxLength="10"
            clearable
          />
        </el-form-item>
        <el-form-item label="活动类型分类：" prop="typeCode">
          <el-select
            v-model="activityTypeForm.typeCode"
            class="dialog_input"
            placeholder="请选择"
            @change="handleTypeCode"
          >
            <el-option
              v-for="item in typeCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="跳转路径："
          prop="jumpUrl"
          v-if="activityTypeForm.typeCode == 4"
        >
          <el-select
            v-model="activityTypeForm.jumpUrl"
            filterable
            allow-create
            clearable
            placeholder="请输入或者选择路径"
          >
            <el-option
              v-for="item in homeJumpUrlList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            />
          </el-select>
          <el-text type="primary" class="tip">
            自定义填写路径需确保路径有效性
          </el-text>
        </el-form-item>
        <el-form-item label="活动类型封面图：" prop="pic">
          <div class="upload">
            <fileUpload
              v-if="activityTypeVisible"
              @getimglist="getimglist"
              ref="uploadFilesDom"
              widthSize="1125"
              heightSize="2436"
              :imgUrl="activityTypeForm.pic"
            />
          </div>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input
            v-model="activityTypeForm.sort"
            placeholder="请输入排序"
            @keyup="numberInput"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="activityTypeSubmit">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="goodsImageList.length"
      :url-list="goodsImageList"
      hide-on-click-modal
      @close="handleClose"
    />
  </div>
</template>

<script setup>
import {
  listNewActivityType,
  addNewActivityType,
  editNewActivityType,
  deleteNewActivityType,
  queryEnum
} from '@/api/activityManagement/activityTypeManagement'
import { homeJumpUrl } from '@/api/getUser/homeManagement'
import fileUpload from '@/components/fileUpload1'
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
const activityTypeFormRef = ref()
const datas = reactive({
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'name', label: '活动类型' },
    { prop: 'typeName', label: '活动类型分类' },
    { slot: 'backgroundUrl' },
    { prop: 'sort', label: '排序' },
    { slot: 'action' }
  ],
  activityTypeForm: {
    type: '',
    typeCode: '',
    pic: '',
    sort: '',
    id: '',
    jumpUrl: ''
  },
  activityTypeFormRules: {
    type: [
      {
        required: true,
        message: '请选择活动类型',
        trigger: 'blur'
      }
    ],
    typeCode: [
      {
        required: true,
        message: '请选择活动类型分类',
        trigger: 'change'
      }
    ],
    jumpUrl: [
      {
        required: true,
        message: '请输入或者选择路径',
        trigger: 'change'
      }
    ],
    pic: [
      {
        required: true,
        message: '请上传活动类型封面图'
      }
    ],
    sort: [
      {
        required: true,
        message: '请输入排序',
        trigger: 'blur'
      }
    ]
  },
  activityTypeList: [],
  activityTypeVisible: false,
  activityTypeTitle: '',
  goodsImageList: [],
  typeCodeList: [],
  homeJumpUrlList: []
})
const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  activityTypeForm,
  activityTypeFormRules,
  activityTypeVisible,
  activityTypeTitle,
  goodsImageList,
  typeCodeList,
  homeJumpUrlList
} = toRefs(datas)

onMounted(() => {
  getHomeJumpUrl()
  getQueryEnum()
  getDataList()
})

// 分类切换
const handleTypeCode = () => {
  activityTypeForm.value.jumpUrl = ''
}

// 获取跳转路径
const getHomeJumpUrl = () => {
  homeJumpUrl().then(({ data: res }) => {
    if (res.code == 200) {
      homeJumpUrlList.value = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 预览图片
const handleClose = () => {
  goodsImageList.value = []
}

// 图片预览
const openImagePreview = row => {
  goodsImageList.value = [row.backgroundUrl]
}
queryEnum
// 图片上传
const getimglist = val => {
  activityTypeForm.value.pic = val
}

const getQueryEnum = () => {
  queryEnum({ type: 1 }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.name,
          value: item.code
        }
      })
      typeCodeList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  listNewActivityType(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 排序限制
const numberInput = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}
// 新增活动弹窗
const addActivity = () => {
  activityTypeTitle.value = '创建活动类型'
  activityTypeVisible.value = true
}

// 编辑
const handleClickEdit = row => {
  activityTypeTitle.value = '修改活动'
  activityTypeForm.value = {
    type: row.name,
    typeCode: row.typeCode,
    pic: row.backgroundUrl,
    sort: row.sort,
    id: row.id,
    jumpUrl: row.jumpUrl
  }
  activityTypeVisible.value = true
}

// 弹窗关闭
const activityTypeClose = () => {
  activityTypeForm.value = {
    type: '',
    typeCode: '',
    pic: '',
    sort: '',
    id: '',
    jumpUrl: ''
  }
  activityTypeFormRef.value.resetFields()
  activityTypeVisible.value = false
}

// 提交
const activityTypeSubmit = () => {
  if (!activityTypeFormRef.value) return
  activityTypeFormRef.value.validate((valid, fields) => {
    if (valid) {
      const data = {
        name: activityTypeForm.value.type,
        typeCode: activityTypeForm.value.typeCode,
        backgroundUrl: activityTypeForm.value.pic,
        jumpUrl: activityTypeForm.value.jumpUrl,
        sort: activityTypeForm.value.sort
      }
      if (activityTypeForm.value.id == '') {
        addNewActivityType(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            activityTypeClose()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = activityTypeForm.value.id
        editNewActivityType(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            activityTypeClose()
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 删除
const handleClickDelete = row => {
  ElMessageBox.confirm('删除后该活动类型无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      deleteNewActivityType({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}
</script>
<style lang="scss" scoped>
:deep(.activityTypeForm) {
  .el-select,
  .upload,
  .el-input {
    width: 300px;
  }
  .el-form-item__content {
    position: relative;
  }
}
.tip {
  display: block;
  width: 220px;
  // position: absolute;
  // top: 5px;
  // right: -220px;
}
</style>
